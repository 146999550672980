<template>
  <article class="message is-danger" v-if="modelState">
    <div class="message-header">
      <p>Validation Errors</p>
    </div>
    <div class="message-body validation">
      <ul>
        <template v-for="(e, eindex) in modelState">
          <li v-for="(r, rindex) in e" :key="rindex + '-' + eindex">{{r}}</li>
        </template>
      </ul>
    </div>
  </article>
</template>
 
<script>
export default {
  name: "validationErrors",
  props: {
    modelState: {
      type: Object
    }
  }
};
</script>

<style>
.validation ul {
  list-style-type: circle;
  list-style-position: inside;
}
</style>


